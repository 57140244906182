/**
 * All entry point mathematics live here
 */
/* eslint-disable */
export default {
  /**
   * Get the closest point on the polygon
   * @param {String} latlng
   * @param {Object} polygon
   */
  getClosestPointOnPolygon(latlng, polygon) {
    var point = []
    var corners = polygon.getPath()
    var previousCorner = null
    var maxDistance = -1
    var minDistance = -1
    var closestPointSoFar = null
    this.closestLine
    for (var j = 0; j <= corners.length; j++) {
      var corner
      if (j === corners.length) {
        corner = corners.getAt(0)
      } else {
        corner = corners.getAt(j)
      }

      if (previousCorner == null) {
        previousCorner = corner
        continue
      }

      var cornerLat = corner.lat()
      var cornerLng = corner.lng()
      var prevCornerLat = previousCorner.lat()
      var prevCornerLng = previousCorner.lng()
      var x0 = latlng.lng()
      var y0 = latlng.lat()
      var x2 = cornerLng
      var y2 = cornerLat
      var x1 = prevCornerLng
      var y1 = prevCornerLat

      /*
        Get closest point from my location on polygon vector
        */
      var closestP = this.getClosestPointOnLine(x2, y2, x1, y1, x0, y0)

      if (closestPointSoFar === null) {
        closestPointSoFar = closestP
        this.closestLine = {}
        this.closestLine.p1 = previousCorner
        this.closestLine.p2 = corner
      }

      if (closestP.distance < closestPointSoFar.distance) {
        closestPointSoFar = closestP
        this.closestLine = {}
        this.closestLine.p1 = previousCorner
        this.closestLine.p2 = corner
      }

      previousCorner = corner
    }

    return closestPointSoFar
  },

  /**
   * getDistanceFromClosestPoint
   * @param {Integer} myX
   * @param {Integer} myY
   * @param {Integer} closestPointX
   * @param {Integer} closestPointY
   * @return {Integer} sqrt
   */
  getDistanceFromClosestPoint(myX, myY, closestPointX, closestPointY) {
    var xDist = closestPointX - myX
    var yDist = closestPointY - myY
    var sum = Math.pow(xDist, 2) + Math.pow(yDist, 2)
    var sqrt = Math.sqrt(sum)
    return sqrt
  },

  /**
   * Get rotation of entry point
   * @param {Object} entryPoint
   * @param {Object} polygon
   * @return {Integer} rotation of the entry point
   */
  getRotation(entryPoint, polygon) {
    var p1 = this.closestLine.p1
    var p2 = this.closestLine.p2
    var x1 = p1.lng()
    var y1 = p1.lat()
    var x2 = p2.lng()
    var y2 = p2.lat()
    var x = x2 - x1
    var y = y2 - y1

    /*
      If your displacements aren't too great (less than a few kilometers) \n\
      and you're not right at the poles, use the quick and dirty estimate that 111,111 meters
      (111.111 km) in the y (lat) direction is 1 degree (of latitude) and 111,111 * cos(latitude)
      meters in the x (lng) direction is 1 degree (of longitude).
      */

    var radians = Math.atan(y / x)
    var degrees = (180 * radians) / Math.PI
    var res
    var deg = degrees

    var fix1 = 45 - Math.abs(Math.abs(deg) - 45)

    var fix2 = Math.abs(y1)
    var fix = fix1 * fix2
    fix = fix * 0.01

    if (degrees > 0) {
      degrees = degrees + Math.abs(fix)
    } else {
      degrees = degrees - Math.abs(fix)
    }
    if (x >= 0 && y >= 0) {
      //northeast
      res = -degrees
    } else if (x >= 0 && y <= 0) {
      //southeast
      res = Math.abs(degrees)
    } else if (x <= 0 && y >= 0) {
      //northwest
      res = -90 - (90 - Math.abs(degrees))
    } else if (x <= 0 && y <= 0) {
      //southwest
      res = 90 + (90 - Math.abs(degrees))
    }

    var clockwise = this.isClockwise(polygon)

    if (clockwise) {
      res += 180
    }

    var x1 = entryPoint.x
    var y1 = entryPoint.y

    return res

    var x1 = entryPoint.x
    var y1 = entryPoint.y
    var center = this.getCenterOfPolygon(polygon)
    var x2 = center.x
    var y2 = center.y
    var x = x2 - x1
    var y = y2 - y1

    var radians = Math.atan(y / x)
    var degrees = (180 * radians) / Math.PI
    var res
    // Northeast
    if (x >= 0 && y >= 0) {
      res = 90 - degrees
    } else if (x >= 0 && y <= 0) {
      res = 90 + Math.abs(degrees)
    } else if (x <= 0 && y <= 0) {
      res = -90 - Math.abs(degrees)
    } else if (x <= 0 && y >= 0) {
      res = -90 + Math.abs(degrees)
    }

    return res
  },

  /**
   * Check if clockwise
   * @param {Object} polygon
   */
  isClockwise(polygon) {
    var sum = 0

    var corners = polygon.getPath()
    var previousCorner = null

    for (var j = 0; j <= corners.length; j++) {
      var corner
      if (j === corners.length) {
        corner = corners.getAt(0)
      } else {
        corner = corners.getAt(j)
      }

      if (previousCorner === null) {
        previousCorner = corner
        continue
      }
      var cornerLat = corner.lat()
      var cornerLng = corner.lng()
      var prevCornerLat = previousCorner.lat()
      var prevCornerLng = previousCorner.lng()

      var x2 = cornerLng
      var y2 = cornerLat
      var x1 = prevCornerLng
      var y1 = prevCornerLat

      var plus = (x2 - x1) * (y2 + y1)

      sum = sum + plus

      previousCorner = corner
    }

    if (sum > 0) {
      return true
    } else {
      return false
    }
  },

  /**
   * Get the center point of polygon
   * @param {Object} polygon google maps object of the polygon
   */
  getCenterOfPolygon(polygon) {
    var arr = polygon.getPath()
    var xMax, xMin, yMax, yMin
    for (var i = 0; i < arr.length; i++) {
      var p = arr.getAt(i)

      var x = p.lng()
      var y = p.lat()
      if (i === 0) {
        xMax = x
        xMin = x
        yMax = y
        yMin = y
        continue
      }

      if (x > xMax) {
        xMax = x
      } else if (x < xMin) {
        xMin = x
      }

      if (y > yMax) {
        yMax = y
      } else if (y < yMin) {
        yMin = y
      }
    }

    var result = {}
    result.x = xMin + (xMax - xMin) / 2
    result.y = yMin + (yMax - yMin) / 2
    return result
  },

  /**
   * Get the closest point on line for snapping the marker
   * @param {Integer} Ax
   * @param {Integer} Ay
   * @param {Integer} Bx
   * @param {Integer} By
   * @param {Integer} Px
   * @param {Integer} Py
   */
  getClosestPointOnLine(Ax, Ay, Bx, By, Px, Py) {
    // VectorAP = (p.x - a.x, p.y - a.y)     //Vector from A to P
    // VectorAB = (b.x - a.x, b.y - a.y)     //Vector from A to B

    var APx = Px - Ax
    var APy = Py - Ay
    var ABx = Bx - Ax
    var ABy = By - Ay

    // MagnitudeAB = vectorAB[x]^2 + vectorAB[y]^2
    // Magnitude of AB vector (it's length)

    var magnitudeAB = Math.pow(ABx, 2) + Math.pow(ABy, 2)
    var ABAPproduct = ABx * APx + ABy * APy
    var distance = ABAPproduct / magnitudeAB

    var returnX
    var returnY
    var caseOF
    if (distance < 0) {
      returnX = Ax
      returnY = Ay
      caseOF = 0
    } else if (distance > 1) {
      returnX = Bx
      returnY = By
      caseOF = 1
    } else {
      returnX = Ax + ABx * distance
      returnY = Ay + ABy * distance
      caseOF = 2
    }

    var dist = this.getDistanceFromClosestPoint(Px, Py, returnX, returnY)
    var res = {}
    res.x = returnX
    res.y = returnY
    res.distance = dist
    res.case = caseOF
    return res
  },

  getAngleFromRotation(rotation) {
    let angle = rotation % 360
    if (angle < 0) {
      angle += 360
    }
    return angle
  }
}
