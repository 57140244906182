<template>
  <home-template>
    <div class="text-start" slot="content">
      <div class="merchat-top-container">
        <v-row class="merchat-top-container-title-label">
          <div class="text-title">Add new location</div>
        </v-row>
        <v-row class="tabs-with-paddings">
          <v-tabs v-model="tab">
            <v-tab class="pl-0"> Info </v-tab>
            <v-tab :disabled="!isTapInfoValidate"> Map </v-tab>
            <v-tab v-if="auth.isParkmanAdmin()"> Advanced </v-tab>
            <!-- <v-tab
          :disabled="!isTapMapValidate">
            Advanced
          </v-tab> -->
          </v-tabs>
        </v-row>
      </div>
      <v-row>
        <v-tabs-items v-model="tab">
          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-info
              ref="tabInfo"
              @validated="tapInfoValidated"
              @formData="tapInfoFormData"
            />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding--map">
            <v-divider />
            <tab-map @validated="tapMapValidated" @formData="tapMapFormData" />
          </v-tab-item>

          <v-tab-item class="tab-item with-horizontal-padding">
            <v-divider />
            <tab-advanced
              :merchant="currentMerchant"
              @validated="tapAdvancedValidated"
              @formData="tapAdvancedFormData"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-row>

      <v-card :elevation="6" class="footer">
        <v-card-actions class="justify-space-between">
          <span>Progress: {{ percentComplete }}</span>
          <v-btn
            class="pkmn-button next-button"
            color="primary"
            large
            @click="next()"
            :disabled="!nextButtonEnabled"
          >
            {{ nextButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <form-confirm-unsave
        v-if="confirmUnsaveDialogVisible"
        flow="location"
        @confirm="goToNext"
        @close="confirmUnsaveDialogVisible = false"
      />
    </div>
  </home-template>
</template>

<script>
import HomeTemplate from '@/templates/Locations'
import TabInfo from '@/components/Locations/AddLocation/InfoForm.vue'
import TabAdvanced from '@/components/Locations/AddLocation/AdvancedSettingsForm.vue'
import TabMap from '@/components/Locations/AddLocation/MapForm.vue'
import merchantHelpers from '@/helpers/merchant'
import * as config from '@/config/config'
import FormConfirmUnsave from '@/components/Merchant/Form/FormConfirmUnsave.vue'
import beforePageLeave from '@/components/Merchant/mixins/beforePageLeave'
import auth from '@/auth'

const TAB = {
  INFO: 0,
  MAP: 1,
  ADVANCED: 2
}
export default {
  name: 'MerchantHomeView',
  components: {
    HomeTemplate,
    TabInfo,
    TabMap,
    TabAdvanced,
    FormConfirmUnsave
  },
  data() {
    return {
      auth: auth,
      isEditingUserInfo: false,
      isFirstLoad: true,
      isShowingDeleteAccountPopup: false,
      isDeletingUser: false,
      tab: '',
      percent: 0,
      isTapInfoValidate: false,
      isTapMapValidate: false,
      isTapAdvancedValidate: false,
      infoForm: null,
      mapForm: null,
      advancedForm: null
    }
  },
  mixins: [beforePageLeave],
  methods: {
    tapInfoValidated(valid) {
      this.isTapInfoValidate = valid
    },
    tapMapValidated(valid) {
      this.isTapMapValidate = valid
    },
    tapAdvancedValidated(valid) {
      this.isTapAdvancedValidate = valid
    },
    tapInfoFormData(data) {
      this.infoForm = data
    },
    tapMapFormData(data) {
      this.mapForm = data
    },
    tapAdvancedFormData(data) {
      this.advancedForm = data
    },
    openMap() {
      this.tab = TAB.MAP
    },
    openAdvanced() {
      this.tab = TAB.ADVANCED
    },
    async next() {
      if (this.isAdvancedTab || (!this.isParkmanAdmin && this.isMapTab)) {
        if (this.allFormsValid) {
          var addLocationFormData = {}

          if (this.isParkmanAdmin) {
            addLocationFormData = {
              ...this.infoForm,
              ...this.mapForm,
              ...this.advancedForm
            }
            addLocationFormData.z_index = parseInt(addLocationFormData.z_index)
            addLocationFormData.capacity = addLocationFormData.capacity
              ? parseFloat(addLocationFormData.capacity)
              : null
            addLocationFormData.commission_fixed = parseFloat(
              addLocationFormData.commission_fixed
            )
            addLocationFormData.commission_percentage = parseFloat(
              addLocationFormData.commission_percentage
            )
            addLocationFormData.kick_back_fee_fixed = parseFloat(
              addLocationFormData.kick_back_fee_fixed
            )
            addLocationFormData.kick_back_fee_percentage = parseFloat(
              addLocationFormData.kick_back_fee_percentage
            )
            addLocationFormData.vat_percentage = parseFloat(
              addLocationFormData.vat_percentage
            )
          } else {
            addLocationFormData = {
              ...this.infoForm,
              ...this.mapForm
            }
          }

          addLocationFormData.merchant_id = this.currentMerchantId
          const location = await merchantHelpers.createLocation(
            addLocationFormData
          )
          const locations = await merchantHelpers.getLocationsByMerchant(
            this.currentMerchantId
          )
          await this.$store.dispatch('setMerchantLocations', locations)
          await this.$store.dispatch('setCurrentLocation', location)
          await this.$store.dispatch('setCurrentProduct', 1)
          this.$store.commit('UPDATE_ADD_LOCATION_FORM_CHANGED', false)
          const currentMerchant = this.$route.params.merchantId
          this.$router.push({
            name: 'AddVisitorParking',
            params: { merchantId: currentMerchant, locationId: location.id }
          })
          return
        }
        await this.$store.dispatch(
          'setSystemMessage',
          this.$t('ask_fill_all_fields')
        )
        return
      }
      this.tab = (this.tab + 1) % 4
    }
  },
  created() {
    this.$store.commit('UPDATE_ADD_LOCATION_FORM_CHANGED', true)
    this.$store.dispatch('setLocationAddingStatus', true)
  },
  destroyed() {
    this.$store.dispatch('setLocationAddingStatus', false)
  },
  computed: {
    isParkmanAdmin() {
      return this.auth.isParkmanAdmin()
    },
    currentMerchantId() {
      return parseInt(this.$route.params.merchantId)
    },
    allFormsValid() {
      if (this.isParkmanAdmin) {
        return (
          this.isTapInfoValidate &&
          this.isTapMapValidate &&
          this.isTapAdvancedValidate
        )
      }
      return this.isTapInfoValidate && this.isTapMapValidate
    },
    nextButtonEnabled() {
      if (this.isInfoTab) {
        return this.isTapInfoValidate
      }
      if (this.isMapTab) {
        return this.isTapMapValidate
      }
      return this.allFormsValid
    },
    isInfoTab() {
      return this.tab === TAB.INFO
    },
    isMapTab() {
      return this.tab === TAB.MAP
    },
    isAdvancedTab() {
      return this.tab === TAB.ADVANCED
    },
    nextButtonText() {
      if (this.isAdvancedTab) {
        return 'Save and Continue'
      } else if (this.isMapTab && !this.isParkmanAdmin) {
        return 'Save and Continue'
      } else {
        return 'Next'
      }
    },
    percentComplete() {
      return this.percent + '%'
    },
    currentMerchant() {
      return this.$store.getters.currentMerchant
    }
  },
  watch: {
    tab(newValue) {
      if (this.isParkmanAdmin) {
        if (this.isInfoTab) {
          this.percent = 0
        } else if (this.isMapTab) {
          this.percent = 33
        } else {
          this.percent = 77
        }
      } else {
        if (this.isInfoTab) {
          this.percent = 0
        } else {
          this.percent = 50
        }
      }
    }
  },
  async mounted() {
    const merchantId = parseInt(this.$route.params.merchantId)
    if (this.$store.getters.countries.length === 0) {
      await merchantHelpers.fetchCountries()
    }
    if (this.$store.getters.currentMerchant === null) {
      await merchantHelpers.fetchMerchant(merchantId)
    }
    await this.$store.dispatch('setSidebar', config.SIDEBAR.MERCHANT)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';
@import '~@/style/sidebar_page';

.form-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.add-location-container {
  padding: 0;
  height: 100%;
}

.add-location-wrapper {
  padding-top: 32px;
  padding-left: 56px;
  height: 100%;
}

.add-location-title {
  font-size: 32px;
  font-weight: 700;
  top: 32px;
  left: 56px;
}

.top-right-button {
  position: absolute;
  top: 32px;
  right: 32px;
}

.custom-divider {
  margin-top: 16px;
  margin-bottom: 16px;
}
.together {
  min-width: 0;
  padding-left: -20px;
}

.selected {
  color: #37bd01;
}

.unselected {
  color: #1f1f1f80;
}

.footer {
  position: absolute;
  padding: 8px 32px;
  bottom: 0 !important;
  right: 0;
  left: $sidebar-width;
}

.next-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  width: 168px;
  height: 46px;
}

.add-location-content {
  padding-top: $page-vertical-padding;
  text-align: start;
}
</style>
