<template>
  <v-form ref="mapForm" class="map-form" lazy-validation>
    <div class="d-flex flex-grow-1">
      <div class="form-wrapper">
        <div class="status-bar" v-if="isReviewing || isWaitingForDeletion">
          <status-box> </status-box>
        </div>
        <label class="map-label"
          >Click on the map to draw area corners. Drag corners to adjust
          borders. <br />Optional: Right-click to add entry, left-click on the
          same spot to remove it</label
        >
        <div class="form-label">Street address</div>
        <vue-google-autocomplete
          v-if="google"
          v-model="formData.street_address"
          v-on:placechanged="onPlaceChanged"
          id="map"
          ref="autocomplete"
          class="custom-suggestion"
          :class="{ 'has-error': hasError && !formData.street_address }"
          :disabled="disabled"
        >
        </vue-google-autocomplete>
        <v-text-field
          v-model="formData.street_address"
          ref="hiddenForm"
          class="hidden-form"
        />

        <div class="toggle-section">
          <label class="toggle-label">Show polygon in the ParkMan app map</label>
          <toggle-button v-model="formData.show_polygon" color="#37BD01" :sync="true"></toggle-button>
        </div>
      </div>
      <div
        class="map-container flex-grow-1"
        style="height: calc(100vh - 135px)"
      >
        <zones-map
          ref="zonesMap"
          :zoom="zoom"
          :zones="zones"
          :map-created="onMapCreated"
          map-type-id="roadmap"
          :options="mapOptions"
          :map-right-clicked="onMapRightClicked"
          :polygon-path-changed="onPolygonPathChanged"
          :zones-loaded="onZonesLoaded"
          :newCenter="center"
          :disabled="disabled"
          :centrePoint.sync="formData.centre_point" 
        >
          <gmap-marker
            v-for="m in filteredEntryPoints"
            :key="m.entry_point"
            :position="m.position"
            :icon="m.icon"
            :draggable="!disabled"
            @dragend="onDragendEntryPoint($event, m)"
            @click="onClickEntryPoint(m)"
          >
          </gmap-marker>
        </zones-map>
      </div>
    </div>
  </v-form>
</template>

<script>
import ZonesMap from '@/components/Common/ZonesMap.vue'
import { MAP_SETTINGS } from '@/helpers/map'
import math from '@/components/Map/calculations'
import helpers from '@/helpers'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import StatusBox from '@/components/Common/StatusBox.vue'
import { gmapApi } from 'vue2-google-maps'
import rules from '@/helpers/validation'
export default {
  components: {
    ZonesMap,
    VueGoogleAutocomplete,
    StatusBox
  },
  props: {
    zonesLoaded: {
      type: Function,
      default: () => {}
    },
    zones: null,
    zone: null,
    zoneId: {
      type: Number,
      default: -1
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasError: false,
      formData: {
        street_address: null,
        postal_code: null,
        state: null,
        entry_points: [],
        polygon: null,
        centre_point: null,
        city_id: 1,
        country_id: 1,
        show_polygon: null
      },
      rules,
      formDataCopy: '',
      center: { lat: 60.165824, lng: 24.930189 },
      zoom: 2,
      marker: null,
      address: null,
      mapOptions: {
        styles: MAP_SETTINGS.styles,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        tilt: 0,
        fullscreenControlOptions: { position: 9 } // google.maps.ControlPosition.RIGHT_BOTTOM is just 9
      },
      drawingManager: null,
      enableDrawingPolygon: false,
      selectedPolygon: null,
      entryPoints: [],
      polygonPath: '',
      polygon: null,
      geocoder: null,
      status_name: ''
    }
  },
  methods: {
    rulesValidation() {
      this.$nextTick(() => {
        this.hasError =
          this.$refs.hiddenForm.$el.classList.contains('error--text')
      })

      return this.$refs.mapForm.validate()
    },
    getZonePaths(zone) {
      const path = zone.polygon.split(',').map((coord) => {
        const [lat, lng] = coord.split(' ')
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      })
      return path
    },
    async onZonesLoaded(polygons) {
      for (let i = 0; i < polygons.length; i++) {
        this.enableDrawingPolygon = false
        polygons[i].options.editable = !this.disabled
        this.polygon = new this.google.maps.Polygon({
          paths: polygons[i].paths,
          options: polygons[i].options
        })
      }
    },
    onPlaceChanged(place) {
      let address = place.route
      if (place.street_number) {
        address += ` ${place.street_number}`
      }
      this.formData.street_address = address
      this.formData.postal_code = place.postal_code
      this.formData.state = place.country
      this.center = { lat: place.latitude, lng: place.longitude }

      if (this.zoneId === -1) {
        this.resetCurrentData()
      }
    },
    addMarker(event) {
      this.marker.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
    },
    /**
     * get icon object for google map marker
     * @param {number} rotation - rotation for the marker
     */
    getEntryPointIcon(rotation) {
      if (this.google === null) { return }
      return {
        path: this.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 3,
        strokeColor: '#00BE14',
        fillColor: '#00BE14',
        fillOpacity: 1,
        strokeWeight: 2,
        rotation
      }
    },
    getMapObject() {
      const zonesMap = this.$refs.zonesMap
      const mapObject = zonesMap.$refs.map.$mapObject
      return mapObject
    },
    onMapCreated() {
      if (this.zoneId === -1) {
        this.enableDrawingPolygon = true
      }
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingMode:
          this.zoneId === -1
            ? this.google.maps.drawing.OverlayType.POLYGON
            : null,
        drawingControl: false,
        polygonOptions: {
          editable: true,
          draggable: true,
          deletable: true
        }
      })
      this.drawingManager.setMap(this.getMapObject())
      this.geocoder = new this.google.maps.Geocoder()

      this.google.maps.event.addListener(
        this.drawingManager,
        'polygoncomplete',
        (polygon) => {
          this.onDrawingComplete(polygon)
        }
      )

      if (this.zones) {
      this.updateFormDataFromLocation(this.zones[0])
      this.status_name = this.zones[0].status_name
      this.calculateEntriesFromLocation(this.zones[0])
      this.enableDrawingPolygon = false
      }
    },
    /**
     * Handler when drawing polygon is done, check if the zone new location is supported
     * @param {Object} $polygon - new drawed google map plygon
     */
    onDrawingComplete(polygon) {
      // $polygon.setMap(null)
      const updatePolygonDetails = () => {
        this.polygonPath = helpers.getPathsFromMvcArray(polygon.getPath())
        this.polygon = polygon
        const center = this.getPolygonCenter(polygon)
        this.formData.centre_point = `${center.lat()} ${center.lng()}`
        this.updatePolygonStringInForm()
      }
      updatePolygonDetails()
      this.enableDrawingPolygon = false
      const path = polygon.getPath()
      this.google.maps.event.addListener(path, 'set_at', (index) => {
        updatePolygonDetails()
      })
      this.google.maps.event.addListener(path, 'insert_at', (index) => {
        updatePolygonDetails()
      })
      this.google.maps.event.addListener(path, 'remove_at', (index) => {
        updatePolygonDetails()
      })
    },
    /**
     * Add entry point on mouse right click
     * @param {Event} $event - google map event
     */
    onMapRightClicked($event) {
      const positionAndRotation = this.getPositionAndRotation($event)
      this.entryPoints.push({
        position: positionAndRotation.position,
        icon: this.getEntryPointIcon(positionAndRotation.icon.rotation),
        delete: false
      })
    },
    onClickEntryPoint(point) {
      if (!this.disabled) {
        point.delete = true
      }
    },
    /**
     * Move the entry point to the new position when mouse drag ended
     * @param {Event} $event - google map event
     * @param {Object} point - a marker for the entry point
     */
    onDragendEntryPoint($event, point) {
      // set the dragged and rotation angle
      const positionAndRotation = this.getPositionAndRotation($event)
      point.position = positionAndRotation.position
      point.icon = {
        ...point.icon,
        rotation: positionAndRotation.icon.rotation
      }
    },
    onDragendCentrePoint($event, point) {
      // set the dragged and rotation angle
      const positionAndRotation = this.getPositionAndRotation($event)
      point.position = positionAndRotation.position
      point.icon = {
        ...point.icon
      }
    },
    /**
     * Method for finding selected polygon map object
     */
    selectedPolygonObject() {
      try {
        const zonesMap = this.$refs.zonesMap
        const polygonsRef = zonesMap.$refs.polygons
        return polygonsRef.find((p) =>
          helpers.areSameZone(p.options.zone_id, this.zone.zone_id)
        )
      } catch (e) {
        return null
      }
    },

    /**
     * When the polygon's path changed, check if there any path left, othewise enable drawing new
     */
    onPolygonPathChanged(polygon) {
      const newPolygon = new this.google.maps.Polygon({
        paths: polygon.paths,
        options: polygon.options
      })

      this.polygonPath = helpers.getPathsFromMvcArray(newPolygon.getPath())
      this.polygon = newPolygon
      this.updatePolygonStringInForm()

      if (!this.polygonPath) {
        this.enableDrawingPolygon = true
      }
    },
    /**
     * Get the center point of polygon. Use when zone boundaries is drawn, get the boundary center to set as map center
     * @param {polygon} polygon
     */
    getPolygonCenter(polygon) {
      const bounds = new this.google.maps.LatLngBounds()
      polygon.getPath().forEach((edge) => {
        bounds.extend(edge)
      })
      return bounds.getCenter()
    },

    /**
     * Get calculated position and entry point rotation from $event
     * @param {Event} $event - google map event
     */
    getPositionAndRotation($event) {
      const closestPoint = math.getClosestPointOnPolygon(
        $event.latLng,
        this.polygon
      )
      const rotation = math.getRotation(closestPoint, this.polygon)
      return {
        position: {
          lat: closestPoint.y,
          lng: closestPoint.x
        },
        icon: {
          rotation
        }
      }
    },
    /**
     * Before loading new data, clear current data
     */
    resetCurrentData() {
      this.entryPoints = []
      this.polygon.setMap(null)
      this.polygon = null
      this.enableDrawingPolygon = true
    },
    calculateEntriesFromLocation(location) {
      this.entryPoints = []
      const points = location.entry_points
      for (var i = 0; i < points.length; i++) {
        const [theLat, theLng] = points[i].entry_point.split(' ').map(Number)
        this.entryPoints.push({
          position: {
            lat: theLat,
            lng: theLng
          },
          icon: this.getEntryPointIcon(points[i].entry_point_angle),
          delete: false
        })
      }
    },
    updateFormDataFromLocation(location) {
      Object.assign(this.formData, {
        street_address: location.street_address,
        postal_code: location.postal_code,
        state: location.state,
        entry_points: location.entry_points,
        polygon: location.polygon,
        centre_point: location.centre_point,
        city_id: location.city_id,
        country_id: location.country_id,
        show_polygon: location.show_polygon
      })
      this.formDataCopy = JSON.stringify(this.formData)
      const path = this.getZonePaths(location)
      this.polygonPath = path
    },
    resetForm(location) {
      if (location.street_address) {
        this.$refs.autocomplete.$el.value = location.street_address
      } else {
        this.$refs.autocomplete.$el.value = ''
      }
      this.updateFormDataFromLocation(location)
      this.calculateEntriesFromLocation(location)
      this.$refs.zonesMap.setMapCenter()
    },
    updatePolygonStringInForm() {
      const polygon = []

      for (let i = 0; i < this.polygonPath.length; i++) {
        polygon.push(`${this.polygonPath[i].lat} ${this.polygonPath[i].lng}`)
      }
      this.formData.polygon = polygon.join(',')
    }
  },
  watch: {
    formData: {
      async handler(newValue) {
        const validated = newValue.polygon !== null
        if (this.zoneId !== -1) {
          const changed = JSON.stringify(newValue) !== this.formDataCopy
          await this.$emit('changed', changed)
        }
        this.$emit('validated', validated)
        if (validated) {
          this.$emit('formData', newValue)
        }
      },
      deep: true
    },
    enableDrawingPolygon(data) {
      this.drawingManager.setDrawingMode(
        data ? this.google.maps.drawing.OverlayType.POLYGON : null
      )
    },
    currentLocation(newValue) {
      if (newValue) {
        this.updateFormDataFromLocation(newValue)
        this.calculateEntriesFromLocation(newValue)
        this.status_name = newValue.status_name
        this.enableDrawingPolygon = false
      }
    },
    filteredEntryPoints(newValue) {
      this.formData.entry_points = []
      if (newValue) {
        for (let i = 0; i < newValue.length; i++) {
          if (!newValue[i].delete) {
            this.formData.entry_points.push({
              entry_point: `${newValue[i].position.lat} ${newValue[i].position.lng}`,
              entry_point_angle: parseInt(
                math.getAngleFromRotation(newValue[i].icon.rotation)
              )
            })
          }
        }
      }
      this.formData.entry_points = this.formData.entry_points.filter(
        (point) =>
          helpers.isNumber(point.entry_point_angle) &&
          helpers.isString(point.entry_point)
      )
    }
  },
  computed: {
    google: gmapApi,
    filteredEntryPoints() {
      return this.entryPoints.filter((m) => !m.delete)
    },
    locationId() {
      return this.$route.params.locationId
    },
    currentLocation() {
      return this.$store.getters.currentLocation
    },
    isReviewing() {
      if (this.zone) {
        return this.status_name === 'pending'
      } else {
        return false
      }
    },
    isWaitingForDeletion() {
      if (this.zone) {
        return this.status_name === 'waiting_for_deletion'
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.d-flex {
  display: flex;
}
.flex-grow-1 {
  flex-grow: 1;
}
.form-label {
  padding-top: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  color: rgba(31, 31, 31, 0.5);
}
.map-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.fill-space {
  height: 100%;
}
.map-form {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.status-bar {
  padding-bottom: 32px;
}
.form-wrapper {
  width: 339px !important;
  height: 100% !important;
  padding-top: 32px;
}

.map-container {
  padding-left: 56px;
  box-sizing: border-box;
}

.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}

.custom-suggestion {
  width: 339px;
  height: 32px;
  padding: 6px 8px;
  background: #f6f6f6;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  .suggestion-content {
    flex: 1;
    color: #333333;
    font-size: 14;
    font-family: Roboto;
    font-weight: 500;
    line-height: 1.4;
    word-wrap: break-word;
  }
  &.has-error {
    border: 1px solid #eb5757 !important;
  }
}
</style>
<style lang="scss">
.map-form {
  .hidden-form {
    padding-top: 0;
    .v-input__slot {
      display: none;
    }
  }
}

.toggle-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}
</style>
