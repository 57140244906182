<template>
  <div class="status-box">
    <div class="status-content">
      <div class="icon-container">
        <img class="icon-img" src="@/assets/info-yellow.svg" alt="Info Icon" />
        <div class="status-message">
          <p class="p">This location is under review by ParkMan staff</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusBox'
}
</script>

<style lang="scss" scoped>
.status-box {
  min-width: 339px;
  height: 32px;
  border-radius: 4px;
  overflow: hidden;
  display: inline-flex;
  background: rgba(255, 165, 0, 0.2);

  .status-content {
    align-self: stretch;
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;

    .icon-container {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon-img {
        width: 16px;
        height: 16px;
      }

      .status-message {
        flex: 1;
      }

      .p {
        margin: 0 !important;
        color: #333333;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 15.6px;
        word-wrap: break-word;
      }
    }
  }
}
</style>
