<template>
  <div class="zones-map-wrapper" style="min-height: 0">
    <gmap-map
      id="map"
      ref="map"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      :options="mapOptions"
      @rightclick="onMapRightClicked($event)"
      @idle="mapReady"
      @zoom_changed="zoomChanged"
    >
      <slot></slot>
      <div v-if="zoom >= zoomThreshold">
        <gmap-polygon
          v-for="(p, i) in polygons"
          :key="i"
          :paths="p.paths"
          :options="p.options"
          ref="polygons"
          :polygon="p"
          @click="onZoneSelected($event, p)"
          @mouseover="onMouseOverZone(p)"
          @mouseout="onMouseOutZone(p)"
          @paths_changed="onPolygonPathsChanged($event, p)"
          :deep-watch="true"
        />
      </div>
    </gmap-map>
  </div>
</template>

<script>
import { MAP_SETTINGS } from '@/helpers/map'
import { gmapApi } from 'vue2-google-maps'
import helpers from '@/helpers'

const TOP_RIGHT_POSITION = 3
const HORIZONTAL_BAR = 1
const COLOR_TYPE = {
  FILL: 0,
  STROKE: 1,
  OPACITY: 2
}
export default {
  name: 'ZonesMap',
  components: {},
  props: {
    mapCreated: {
      type: Function,
      default: () => {}
    },
    zonesLoaded: {
      type: Function,
      default: () => {}
    },
    zoneSelected: {
      type: Function,
      default: () => {}
    },
    zoomForZoneSelected: {
      type: Number,
      default: 14
    },
    boundsChanged: {
      type: Function,
      default: () => {}
    },
    zonePolygonSelected: {
      type: Function,
      default: () => {}
    },
    mapRightClicked: {
      type: Function,
      default: () => {}
    },
    polygonPathChanged: {
      type: Function,
      default: (polygon) => {}
    },
    zones: {
      type: Array,
      default: () => []
    },
    shouldChangeCenter: {
      type: Function,
      default: () => {}
    },
    newCenter: {
      type: Object
    },
    shouldZoomToCenter: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zoom: 18,
      zoomThreshold: 14,
      mapCentered: false,
      center: { lat: 60.165824, lng: 24.930189 },
      mapOptions: {
        styles: MAP_SETTINGS.styles,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        scrollwheel: true,
        mapTypeControl: true,
        tilt: 10,
        fullscreenControlOptions: { position: 9 }, // google.maps.ControlPosition.RIGHT_BOTTOM is just 9,
        zoomControlOptions: {
          position: TOP_RIGHT_POSITION // you can set different position here
        },
        mapTypeControlOptions: {
          style: HORIZONTAL_BAR,
          position: TOP_RIGHT_POSITION // you can set different position here
        }
      },
      polygons: [],
      oldBoundsForZones: null,
      searchInput: '',
      googleAutoCompleteService: null,
      geocoder: null,
      autoCompleteZoneData: [],
      autoCompleteAddressData: [],
      prevSelectedZoneId: '',
      isHovering: false,
      showingZoneMarkers: {}
    }
  },
  methods: {
    /**
     * Get the boundary for zones, max fit them
     * @param {Object[]} zones
     */
    getZonesBounds(zones) {
      let maxLat
      let minLat
      let maxLng
      let minLng

      for (let i = 0; i < zones.length; i++) {
        const zone = zones[i]
        const paths = this.getZonePaths(zone)
        for (var j = 0; j < paths.length; j++) {
          const latlng = paths[j]
          const lat = latlng.lat
          const lng = latlng.lng

          if (i === 0 && j === 0) {
            maxLat = lat
            minLat = lat
            maxLng = lng
            minLng = lng
            continue
          }

          if (lat > maxLat) {
            maxLat = lat
          } else if (lat < minLat) {
            minLat = lat
          }

          if (lng > maxLng) {
            maxLng = lng
          } else if (lng < minLng) {
            minLng = lng
          }
        }
      }

      if (this.google) {
        const sw = new this.google.maps.LatLng(minLat, minLng)
        const ne = new this.google.maps.LatLng(maxLat, maxLng)
        const bounds = new this.google.maps.LatLngBounds(sw, ne)
        return bounds
      }
    },

    /**
     * Get the cetner for all zones
     */
    setZonesCenter() {
      if (this.$refs.map) {
        const bounds = this.getZonesBounds(this.zones)
        this.$refs.map.fitBounds(bounds)
        this.center = bounds?.getCenter()
      }
    },
    /**
     * Set center for map
     */
    setMapCenter() {
      if (this.zones.length > 0 && this.google) {
        this.polygons = this.getPolygons(this.zones)
        this.zonesLoaded(this.polygons)
        this.setZonesCenter()
      }
    },
    addZonesMarker() {
      for (let i = 0; i < this.zones.length; i++) {
        const zone = this.zones[i]
        if (this.google && !this.showingZoneMarkers[zone.id]) {
          const hiddenMarker = this.createMarker(zone)
          this.showingZoneMarkers[zone.id] = hiddenMarker
          hiddenMarker.addListener('click', () => {
            this.$refs.map.$mapObject.panTo(hiddenMarker.getPosition())
            if (this.zoom !== 18) {
              this.$refs.map.$mapObject.setZoom(18)
            }
          })

          hiddenMarker.addListener("dragend", () => {
          const newPosition = hiddenMarker.getPosition();
          var markerPosition = `${newPosition.lat()} ${newPosition.lng()}`;

          // If you want to update the `centrePoint` prop to parent component
          this.$emit("update:centrePoint", markerPosition);
        });
        }
      }
    },
    createMarker(zone) {
      const oneZoneMap = this.zones.length === 1
      const [lat, lng] = zone.centre_point.split(" ").map(Number)
      const markerLoc = new this.google.maps.LatLng(lat, lng)
      return new this.google.maps.Marker({
        position: markerLoc,
        optimized: true,
        visible: true,
        draggable: oneZoneMap,
        map: this.$refs.map?.$mapObject,
        icon: require('@/assets/zone_pin.png'),
        originalPosition: markerLoc
      })
    },

    /**
     * Default helsinki
     */
    setCenterToDefault() {
      this.center = { lat: 60.15, lng: 24.95 }
    },
    /**
     * Fetch zones data, only show the zones within the viewport
     */
    fetchZonesData() {
      this.polygons = this.getPolygons(this.zones)
      this.zonesLoaded(this.polygons)
    },

    getEmptyPolygonForDrawing() {
      // empty polygon is used for creating new zone
      const emptyPolygon = {
        paths: null,
        options: {
          zone_id: 0,
          strokeWeight: 2,
          strokeOpacity: 1,
          fillColor: '#fff',
          strokeColor: '#777'
        }
      }
      const existingEmptyPolygon = this.polygons.find((polygon) =>
        helpers.areSameZone(polygon.options.zone_id, '0')
      )
      return existingEmptyPolygon || emptyPolygon
    },

    /**
     * Get polygons data from zones
     * @param {Object[]} zones
     */
    getPolygons(zones) {
      const polygons = zones.map((zone) => {
        return {
          paths: this.getZonePaths(zone),
          options: this.getZoneOptions(zone)
        }
      })
      return polygons
    },

    /**
     * Get polygon options from zone
     * @param {Object} zone
     */
    getZoneOptions(zone) {
      return {
        name: this.getZoneName(zone),
        editable: false,
        strokeWeight: this.getStrokeWeight(),
        zone_id: zone.id,
        merchantId: zone.merchant_id,
        fillColor: this.getColor(zone, COLOR_TYPE.FILL),
        fillOpacity: this.getColor(zone, COLOR_TYPE.OPACITY),
        strokeColor: this.getColor(zone, COLOR_TYPE.STROKE),
        strokeOpacity: 1,
        zIndex: zone.z_index
      }
    },

    getStrokeWeight() {
      return this.isSelected() || this.isHovering ? 2 : 1
    },

    getColor(zone, type, editable = false) {
      let fill, stroke
      let opacity = 1

      if (this.isActive(zone)) {
        opacity = 1
        fill = '#00bc00'
        stroke = '#009900'
      } else {
        fill = '#BBBBBB'
        stroke = '#999999'
      }

      if (this.isStreetZone(zone) || editable) {
        opacity = 0.3
      }

      if (this.isPending(zone) === true) {
        stroke = '#999900'
        fill = '#cccc00'
      }

      if (this.isZoneDisapproved(zone)) {
        stroke = '#ff0000'
        fill = '#ff0000'
      }

      if (type === COLOR_TYPE.FILL) {
        return fill
      } else if (type === COLOR_TYPE.STROKE) {
        return stroke
      } else if (type === COLOR_TYPE.OPACITY) {
        return opacity
      }
    },

    isStreetZone(zone) {
      return zone.location_type_name === 'street_parking'
    },

    isGarage(zone) {
      return zone.location_type_name === 'garage'
    },

    isActive(zone) {
      return (
        zone.status_name === 'active' &&
        zone.location_products &&
        zone.location_products[0]?.status === 'available'
      )
    },

    isPending(zone) {
      return zone.status_name === 'pending'
    },

    isZoneDisapproved(zone) {
      return zone.status_name === 'denied'
    },

    isSelected() {
      return this.selectedZone
    },

    /**
     * Display name for the zone, 'provider_name zone_name'
     * @param {Object} zone
     */
    getZoneName(zone) {
      return zone.name
    },

    /**
     * Get polygon paths from zone
     * @param {Object} zone
     */
    getZonePaths(zone) {
      const path = String(zone.polygon)
        .split(',')
        .map((coord) => {
          const [lat, lng] = coord.split(' ')
          return {
            lat: parseFloat(lat.replace(',', '.')),
            lng: parseFloat(lng.replace(',', '.'))
          }
        })
      return path
    },
    /**
     * Show overlay loader
     */
    showSpinner() {
      this.$store.dispatch('showSpinner')
    },

    /**
     * Hide overlay loader
     */
    hideSpinner() {
      this.$store.dispatch('hideSpinner')
    },

    /**
     * Init method when mounted
     */
    init() {
      // show loading before the map loaded
      // this.showSpinner()
      this.$refs.map.$mapPromise.then(() => {
        this.geocoder = new this.google.maps.Geocoder()
        this.mapCreated()
      })
    },
    /**
     * Focus on the selected zone
     */
    showSelectedZone(selectedZone) {
      this.center = selectedZone.position
      // zoom closer to the zone
      this.zoom = this.zoomForZoneSelected
    },
    /**
     * Event when mouse hover polygon
     * @param {Object} polygon
     */
    onMouseOverZone(polygon) {
      if (
        !this.selectedZone ||
        polygon.options.zone_id !== this.selectedZone.zone_id
      ) {
        polygon.options.strokeWeight = 2
      }
    },

    /**
     * Event when mouse out polygon
     * @param {Object} polygon
     */
    onMouseOutZone(polygon) {
      if (
        !this.selectedZone ||
        polygon.options.zone_id !== this.selectedZone.zone_id
      ) {
        polygon.options.strokeWeight = 1
      }
    },

    /**
     * Event when clicked on polygon
     * @param {Event} $event - google map event
     * @param {Object} polygon
     */
    onZoneSelected($event, polygon) {
      // when clicked on the vertex, removed the path
      if (typeof $event.vertex !== 'undefined') {
        this.onPolygonPathDelete($event, polygon)
      } else {
        const selectedZone = this.zones.find((zone) =>
          helpers.areSameZone(zone.id, polygon.options.zone_id)
        )
        if (selectedZone) {
          this.zoneSelected(selectedZone)
        }
        this.zonePolygonSelected(polygon)
      }
    },

    /**
     * Event when polygon paths changed
     * @param {Object} mvcPaths
     * @param {Object} polygon
     */
    onPolygonPathsChanged(mvcPaths, polygon) {
      const newPolygon = polygon
      newPolygon.paths = helpers.getPathsFromMvcPaths(mvcPaths)
      this.polygonPathChanged(newPolygon)
    },

    /**
     * Event when polygon path delete
     * @param {Event} $event - google map event
     * @param {Object} polygon
     */
    onPolygonPathDelete($event, polygon) {
      if (polygon.options.editable) {
        const $polygonObject = this.getPolygonObject(polygon).$polygonObject
        const path = $polygonObject.getPath()
        path.removeAt($event.vertex)
        $polygonObject.setPaths(path)
        this.polygonPathChanged()
      }
    },

    /**
     * Get google map polygon object
     * @param {Object} polygon
     */
    getPolygonObject(polygon) {
      return this.$refs.polygons.find((p) =>
        helpers.areSameZone(p.options.zone_id, polygon.options.zone_id)
      )
    },

    /**
     * Event on map right click
     * @param {Event} $event - google map event
     */
    onMapRightClicked($event) {
      this.mapRightClicked($event)
    },

    showClusters() {
      for (let i = 0; i < this.zones.length; i++) {
        const zone = this.zones[i]
        if (this.showingZoneMarkers[zone.id]) {
          this.showingZoneMarkers[zone.id].setVisible(true)
        }
      }
    },

    hideClusters() {
      Object.keys(this.showingZoneMarkers).forEach((id) => {
        this.showingZoneMarkers[id].setVisible(false)
      })
    },

    zoomChanged() {
      // if (this.zoom === this.$refs.map?.$mapObject?.zoom) {
      //   return
      // }
      // this.zoom = this.$refs.map?.$mapObject?.zoom
      // if (this.zoom < this.zoomThreshold) {
      //   this.$nextTick(() => {
      //     this.hideClusters()
      //   })
      // } else {
      //   this.$nextTick(() => {
      //     this.showClusters()
      //   })
      // }
    },
    reInit() {
      this.polygons = this.getPolygons(this.zones)
      this.zonesLoaded(this.polygons)
      this.setMapCenter()
      this.addZonesMarker()
      this.zoomChanged()
    },

    mapReady() {
      if (!this.mapCentered && this.zones.length > 0) {
        this.setMapCenter()
        this.addZonesMarker()
        this.zoomChanged()
        this.mapCentered = true
      }
    }
  },
  computed: {
    google: gmapApi,
    currentZone() {
      return this.$store.getters.currentLocation
    },
    googleMaps() {
      return this.google.maps
    },
    selectedZone() {
      if (!this.currentZone) {
        return false
      }

      return this.zones.find((zone) =>
        helpers.areSameZone(zone.id, this.currentZone.id)
      )
    }
    // spinnerShowing () {
    //   return this.$store.state.spinnerShowing
    // }
  },
  watch: {
    currentZone: {
      immediate: true,
      handler(newZones) {
        this.setMapCenter()
      }
    },
    newCenter(newValue) {
      this.center = newValue
    },
    shouldZoomToCenter(newValue) {
      if (newValue) {
        this.setMapCenter()
      }
    },
    zones(newValue, prevValue) {
      if (newValue !== prevValue) {
        this.reInit()
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.zones-map-wrapper {
  position: relative;
  height: 100%;
}
#map {
  width: 100%;
  height: 100%;
  min-width: 200px;
}
</style>
