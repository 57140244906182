<template>
  <div class="location-info-form">
    <status-box v-if="isReviewing || isWaitingForDeletion"> </status-box>
    <v-form ref="infoForm" class="info-form">
      <v-row style="height: 72px">
        <v-col>
          <div class="form-label">Parking location name</div>
          <v-text-field
            solo
            flat
            background-color="#F6F6F6"
            v-model="formData.name"
            dense
            :rules="[rules.required]"
            class="form-font pkmn-input error-border"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Capacity (optional)</div>
          <v-text-field
            solo
            class="form-font pkmn-input error-border"
            flat
            type="number"
            background-color="#F6F6F6"
            v-model="capacity"
            dense
            :rules="[capacityRule()]"
            :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Merchant cost center (optional)</div>
          <v-text-field
            solo
            class="form-font pkmn-input error-border"
            v-model="formData.cost_center"
            flat
            background-color="#F6F6F6"
            dense
            :disabled="disabled"
          >
            <template v-slot:append>
              <v-tooltip bottom color="rgba(0, 0, 0, 0)">
                <template v-slot:activator="{ on }">
                  <div class="info-icon" v-on="on">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0968 14.1088L10.8919 14.9465C10.2771 15.1892 9.78602 15.3739 9.42033 15.5008C9.05424 15.6282 8.62887 15.6916 8.14422 15.6916C7.39996 15.6916 6.82106 15.5092 6.40818 15.1471C5.9953 14.7835 5.78879 14.3228 5.78879 13.7639C5.78879 13.5475 5.80377 13.325 5.83493 13.0982C5.86634 12.8712 5.91629 12.6155 5.98465 12.3298L6.75297 9.61035C6.82132 9.34995 6.87942 9.10322 6.92595 8.8699C6.97314 8.63802 6.99588 8.42468 6.99588 8.23264C6.99588 7.88535 6.92398 7.64243 6.78083 7.50586C6.63769 7.36967 6.36454 7.30053 5.95902 7.30053C5.7604 7.30053 5.55626 7.33234 5.34817 7.39386C5.13917 7.45564 4.96053 7.51532 4.81055 7.57079L5.016 6.73241C5.51932 6.52748 6.00042 6.352 6.46062 6.20635C6.92083 6.06031 7.35566 5.98723 7.76696 5.98723C8.5061 5.98723 9.07646 6.16587 9.47698 6.52314C9.87751 6.88068 10.0777 7.34417 10.0777 7.91532C10.0777 8.03349 10.0646 8.24171 10.0363 8.53931C10.0087 8.83756 9.9573 9.11084 9.88237 9.35942L9.11734 12.0679C9.05464 12.2855 8.99824 12.5342 8.94921 12.814C8.89861 13.092 8.87442 13.3045 8.87442 13.4469C8.87442 13.8066 8.9546 14.0521 9.11537 14.1827C9.27731 14.3132 9.55624 14.3781 9.95243 14.3781C10.1384 14.3781 10.3501 14.3451 10.5852 14.2803C10.8197 14.2155 10.9907 14.1586 11.0968 14.1088ZM11.2908 2.73768C11.2908 3.20958 11.113 3.6126 10.7556 3.94399C10.3991 4.27655 9.96952 4.44297 9.46699 4.44297C8.96289 4.44297 8.53226 4.27655 8.17169 3.94399C7.81179 3.61247 7.63144 3.20958 7.63144 2.73768C7.63144 2.2667 7.81179 1.86302 8.17169 1.52743C8.5316 1.19237 8.96302 1.0249 9.46699 1.0249C9.96939 1.0249 10.3991 1.19276 10.7556 1.52743C11.1133 1.86302 11.2908 2.26683 11.2908 2.73768Z"
                        fill="#B9B9B9"
                      />
                    </svg>
                  </div>
                </template>
                <div class="tooltip-content">
                  <span
                    >This ID is used for merchant reporting and
                    book-keeping</span
                  >
                </div>
                <div class="tooltip-arrow"></div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Location type</div>
          <v-select
            v-model="formData.location_type_id"
            :items="parkingTypes"
            item-value="id"
            item-text="name"
            solo
            flat
            dense
            :rules="[rules.required]"
            background-color="#F6F6F6"
            class="form-font pkmn-input error-border"
            :disabled="disabled"
          >
            <template v-slot:append>
              <img :src="dropdownNarrow" />
            </template>
          </v-select>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Location code</div>
          <v-text-field
            solo
            class="form-font pkmn-input error-border"
            v-model="formData.location_code"
            flat
            :rules="[rules.required]"
            background-color="#F6F6F6"
            dense
            :disabled="disabled"
          >
            <template v-slot:append>
              <v-tooltip bottom color="rgba(0, 0, 0, 0)">
                <template v-slot:activator="{ on }">
                  <div class="info-icon" v-on="on">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0968 14.1088L10.8919 14.9465C10.2771 15.1892 9.78602 15.3739 9.42033 15.5008C9.05424 15.6282 8.62887 15.6916 8.14422 15.6916C7.39996 15.6916 6.82106 15.5092 6.40818 15.1471C5.9953 14.7835 5.78879 14.3228 5.78879 13.7639C5.78879 13.5475 5.80377 13.325 5.83493 13.0982C5.86634 12.8712 5.91629 12.6155 5.98465 12.3298L6.75297 9.61035C6.82132 9.34995 6.87942 9.10322 6.92595 8.8699C6.97314 8.63802 6.99588 8.42468 6.99588 8.23264C6.99588 7.88535 6.92398 7.64243 6.78083 7.50586C6.63769 7.36967 6.36454 7.30053 5.95902 7.30053C5.7604 7.30053 5.55626 7.33234 5.34817 7.39386C5.13917 7.45564 4.96053 7.51532 4.81055 7.57079L5.016 6.73241C5.51932 6.52748 6.00042 6.352 6.46062 6.20635C6.92083 6.06031 7.35566 5.98723 7.76696 5.98723C8.5061 5.98723 9.07646 6.16587 9.47698 6.52314C9.87751 6.88068 10.0777 7.34417 10.0777 7.91532C10.0777 8.03349 10.0646 8.24171 10.0363 8.53931C10.0087 8.83756 9.9573 9.11084 9.88237 9.35942L9.11734 12.0679C9.05464 12.2855 8.99824 12.5342 8.94921 12.814C8.89861 13.092 8.87442 13.3045 8.87442 13.4469C8.87442 13.8066 8.9546 14.0521 9.11537 14.1827C9.27731 14.3132 9.55624 14.3781 9.95243 14.3781C10.1384 14.3781 10.3501 14.3451 10.5852 14.2803C10.8197 14.2155 10.9907 14.1586 11.0968 14.1088ZM11.2908 2.73768C11.2908 3.20958 11.113 3.6126 10.7556 3.94399C10.3991 4.27655 9.96952 4.44297 9.46699 4.44297C8.96289 4.44297 8.53226 4.27655 8.17169 3.94399C7.81179 3.61247 7.63144 3.20958 7.63144 2.73768C7.63144 2.2667 7.81179 1.86302 8.17169 1.52743C8.5316 1.19237 8.96302 1.0249 9.46699 1.0249C9.96939 1.0249 10.3991 1.19276 10.7556 1.52743C11.1133 1.86302 11.2908 2.26683 11.2908 2.73768Z"
                        fill="#B9B9B9"
                      />
                    </svg>
                  </div>
                </template>
                <div class="tooltip-content">
                  <span
                    >Parking area ID info box: This ID comes from the provider
                    and is needed for wardering and settlement reports.</span
                  >
                </div>
                <div class="tooltip-arrow"></div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Visible in PM app from</div>
          <v-menu
            v-model="menuVisibleFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            :disabled="disabled"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="form-font"
                v-model="visibleFrom"
                readonly
                solo
                flat
                :rules="[rules.required]"
                dense
                background-color="#F6F6F6"
                v-on="on"
                :disabled="disabled"
              >
                <template v-slot:append>
                  <div @click="handleIconClick" class="clickable">
                    <img :src="dropdownNarrow" />
                  </div>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              no-title
              color="primary"
              v-model="visibleFromDate"
              @input="menuVisibleFrom = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Visible in PM app until</div>
          <v-menu
            v-model="menuVisibleUntil"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            class="menu-style"
            :disabled="disabled"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="form-font"
                v-model="visibleUntil"
                readonly
                solo
                :rules="[rules.required]"
                v-on="on"
                flat
                dense
                background-color="#F6F6F6"
                :disabled="disabled"
              >
                <template v-slot:append>
                  <div @click="handleIconClickUntil" class="clickable">
                    <img :src="dropdownNarrow" />
                  </div>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="visibleUntilDate"
              color="primary"
              no-title
              :allowed-dates="disablePastDates"
              :disabled="untilFurtherNotice"
              @input="menuVisibleUntil = false"
            >
            </v-date-picker>
            <v-row class="menu-style">
              <v-col>
                <v-switch
                  color="primary"
                  v-model="untilFurtherNotice"
                  class="switch-wrapper"
                >
                  <template v-slot:label>
                    <span class="input__label">Until further notice</span>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-menu>
        </v-col>
      </v-row>
      <div
        class="delete-button--container"
        @click="deleteLocation"
        v-if="shouldShowDeleteButton"
      >
        <div
          class="delete-button-content"
          :class="{ 'under-review': isWaitingForDeletion }"
        >
          <div class="delete-button-title">
            <span>Delete location</span>
          </div>
        </div>
      </div>
    </v-form>
    <form-delete-location
      v-if="isDeleteLocation"
      :data="this.data"
      @close="isDeleteLocation = false"
      @locationDeleted="locationDeleted"
    />
  </div>
</template>

<script>
import dropdownNarrow from '@/assets/dropdown_arrow.svg'
import moment from 'moment'
import StatusBox from '@/components/Common/StatusBox.vue'
import FormDeleteLocation from '@/components/Locations/Form/FormDeleteLocation'
import auth from '@/auth'
import i18n from '@/plugins/i18n'
import helpers from '@/helpers'
import rules from '@/helpers/validation'

export default {
  components: {
    FormDeleteLocation,
    StatusBox
  },
  props: {
    location: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDeleteLocation: false,
      auth: auth,
      rules,
      dropdownNarrow,
      parkingTypes: [
        {
          id: 1,
          name: 'Garage'
        },
        {
          id: 2,
          name: 'Open Area'
        },
        {
          id: 3,
          name: 'Street Parking'
        }
      ],
      visibleFrom: '',
      menuVisibleFrom: false,
      visibleUntil: 'Further notice',
      menuVisibleUntil: false,
      visibleFromDate: null,
      visibleUntilDate: null,
      date: null,
      untilFurtherNotice: true,
      maxDate: new Date('2023-12-31'),
      capacity: null,
      formData: {
        name: '',
        location_type_id: null,
        capacity: null,
        location_code: '',
        visible_from_date: null,
        visible_to_date: null,
        cost_center: null
      },
      visibleFromCopy: null,
      visibleUntilCopy: null
    }
  },
  methods: {
    capacityRule() {
      return (value) => {
        if (!value) {
          return true
        }
        return (
          value > 0 || i18n.t('error_value_should_be_bigger_than', { value: 0 })
        )
      }
    },
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10)
    },
    validate() {
      return (
        this.formData.name &&
        this.formData.location_type_id !== null &&
        this.formData.location_code !== null &&
        (!this.formData.capacity || this.formData.capacity > 0)
      )
    },
    deleteLocation() {
      if (this.isWaitingForDeletion === false) {
        this.isDeleteLocation = true
      }
    },
    locationDeleted() {
      this.isDeleteLocation = false
      this.$emit('locationDeleted')
    },
    getDateString() {},
    handleIconClick() {
      // Open the menu when the icon is clicked
      this.menuVisibleFrom = !this.menuVisibleFrom
    },
    handleIconClickUntil() {
      // Open the menu when the icon is clicked
      this.menuVisibleUntil = !this.menuVisibleUntil
    },
    updateFormDataFromLocation(location) {
      this.formData.name = location.name
      this.formData.location_type_id = location.location_type_id
      this.capacity = location.capacity
      this.formatDate.capacity = location.capacity
      this.formData.location_code = location.location_code
      this.visibleFromDate = this.formatDate(location.visible_from_date)
      if (location.visible_to_date) {
        this.untilFurtherNotice = false
        this.visibleUntilDate = this.formatDate(location.visible_to_date)
        this.visibleUntilCopy = moment(this.visibleUntilDate).format(
          'DD.MM.YYYY'
        )
      } else {
        this.untilFurtherNotice = true
        this.formData.visible_to_date = null
        this.visibleUntilCopy = 'Further notice'
      }
      this.visibleFromCopy = moment(this.visibleFromDate).format('DD.MM.YYYY')
    },
    formatDate(inputDate) {
      const date = moment.utc(inputDate).local()
      return date.format('YYYY-MM-DD')
    },
    rulesValidation() {
      return this.$refs.infoForm.validate()
    }
  },
  mounted() {
    const date = moment()
    this.visibleFromDate = date.format('YYYY-MM-DD')
    this.visibleUntilDate = date.format('YYYY-MM-DD')
    if (this.location) {
      this.updateFormDataFromLocation(this.location)
    }
  },
  computed: {
    isFormChanged() {
      if (this.location) {
        return (
          this.formData.name !== this.location.name ||
          this.formData.capacity !== this.location.capacity ||
          this.formData.location_type_id !== this.location.location_type_id ||
          this.formData.location_code !== this.location.location_code ||
          this.visibleFrom !== this.visibleFromCopy ||
          this.visibleUntil !== this.visibleUntilCopy
        )
      } else {
        return false
      }
    },
    isViewing() {
      return location !== null
    },
    isReviewing() {
      if (this.location) {
        return this.location.status_name === 'pending'
      } else {
        return false
      }
    },
    isWaitingForDeletion() {
      if (this.location) {
        return this.location.status_name === 'waiting_for_deletion'
      } else {
        return false
      }
    },
    shouldShowDeleteButton() {
      if (auth.isParkmanAdmin() && this.location !== null) {
        return true
      }
      return false
    }
  },
  watch: {
    capacity(newValue) {
      this.formData.capacity = helpers.isNumber(parseInt(newValue))
        ? parseInt(newValue)
        : null
    },
    isFormChanged(newValue) {
      if (this.location) {
        this.$emit('changed', newValue)
      }
    },
    formData: {
      handler(newValue) {
        const validated =
          newValue.name &&
          newValue.location_type_id &&
          newValue.location_code &&
          (newValue.capacity > 0 || !newValue.capacity)
        const validatedDate =
          (this.untilFurtherNotice && newValue.visible_from_date) ||
          (newValue.visible_from_date && newValue.visible_to_date)

        this.$emit('validated', validated && validatedDate)
        if (validated) {
          this.$emit('formData', newValue)
        }
      },
      deep: true
    },
    location(newValue) {
      if (newValue) {
        this.updateFormDataFromLocation(newValue)
      }
    },
    visibleFromDate(newValue) {
      if (newValue) {
        const date = moment(newValue)
        this.visibleFrom = date.format('DD.MM.YYYY')
        this.formData.visible_from_date =
          date.format('YYYY-MM-DDT00:00:00.000') + 'Z'
      }
    },
    visibleUntilDate(newValue) {
      if (newValue) {
        const date = moment(newValue)
        if (this.untilFurtherNotice) {
          this.visibleUntil = 'Further notice'
          this.formData.visible_to_date = null
        } else {
          this.visibleUntil = date.format('DD.MM.YYYY')
          this.formData.visible_to_date =
            date.format('YYYY-MM-DDT00:00:00.000') + 'Z'
        }
      }
    },
    untilFurtherNotice(newValue) {
      if (newValue === true) {
        this.visibleUntil = 'Further notice'
        this.formData.visible_to_date = null
      } else {
        this.visibleUntil = moment(this.visibleUntilDate).format('DD.MM.YYYY')
        this.formData.visible_to_date =
          moment(this.visibleUntilDate).format('YYYY-MM-DDT00:00:00.000') + 'Z'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.v-select__slot {
  height: 32px;
  padding: 14px 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.info-form-container {
  height: 100% !important;
}

.fill-space {
  height: 100%;
}
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}

.form-font .v-input__slot {
  --padding: 10px;
  background: #eaeaea !important;
}
.info-form {
  width: 339px;
  height: 100% !important;
  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 120%;
    color: rgba(31, 31, 31, 0.5);
    &.under-review {
      color: #ffa500 !important; /* Change color for under review status */
    }
  }

  .v-select .v-select__selections {
    height: 48px;
  }
  .v-menu__content {
    background-color: #fff;
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}

.v-date-picker {
  width: 321px;
  height: 336px;
}

.menu-style {
  background-color: white;
}

.switch-wrapper {
  padding-left: 15px;
}
.input__label {
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}
.clickable {
  cursor: pointer;
}

.tooltip-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  width: 168px;
  background-color: white;
  color: black;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.info-icon {
  cursor: pointer;
}

.info-icon:hover path {
  fill: #37bd01;
}

.form-font {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.location-info-form {
  padding-top: 32px !important;
  .v-text-field__details {
    padding-left: 0 !important;
  }
}

.delete-button--container {
  width: 314px;
  height: 46px;
  padding-top: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  .delete-button-content {
    text-align: center;
    color: #eb5757;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 22.4px;
    word-wrap: break-word;
    cursor: pointer;
    &.under-review {
      color: #b9b9b9; /* Change color for under review status */
    }
  }
}
</style>
<style lang="scss">
.location-info-form {
  .v-text-field__details {
    padding-left: 0 !important;
  }
}
</style>
