<template>
  <div class="add-user-table">
    <v-form ref="settingsForm" class="advanced-settings-form">
      <status-box v-if="isReviewing || isWaitingForDeletion"> </status-box>
      <v-row style="height: 72px">
        <v-col cols="12">
          <div class="form-label">Z-index</div>
          <v-text-field
            v-model="formData.z_index"
            class="form-font pkmn-input error-border"
            background-color="#F6F6F6"
            type="number"
            min="0"
            solo
            flat
            dense
            :rules="[rules.hasValue, rules.onlyPositive]"
          >
            <template v-slot:append>
              <v-tooltip bottom color="rgba(0, 0, 0, 0)">
                <template v-slot:activator="{ on }">
                  <div class="info-icon" v-on="on">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0968 14.1088L10.8919 14.9465C10.2771 15.1892 9.78602 15.3739 9.42033 15.5008C9.05424 15.6282 8.62887 15.6916 8.14422 15.6916C7.39996 15.6916 6.82106 15.5092 6.40818 15.1471C5.9953 14.7835 5.78879 14.3228 5.78879 13.7639C5.78879 13.5475 5.80377 13.325 5.83493 13.0982C5.86634 12.8712 5.91629 12.6155 5.98465 12.3298L6.75297 9.61035C6.82132 9.34995 6.87942 9.10322 6.92595 8.8699C6.97314 8.63802 6.99588 8.42468 6.99588 8.23264C6.99588 7.88535 6.92398 7.64243 6.78083 7.50586C6.63769 7.36967 6.36454 7.30053 5.95902 7.30053C5.7604 7.30053 5.55626 7.33234 5.34817 7.39386C5.13917 7.45564 4.96053 7.51532 4.81055 7.57079L5.016 6.73241C5.51932 6.52748 6.00042 6.352 6.46062 6.20635C6.92083 6.06031 7.35566 5.98723 7.76696 5.98723C8.5061 5.98723 9.07646 6.16587 9.47698 6.52314C9.87751 6.88068 10.0777 7.34417 10.0777 7.91532C10.0777 8.03349 10.0646 8.24171 10.0363 8.53931C10.0087 8.83756 9.9573 9.11084 9.88237 9.35942L9.11734 12.0679C9.05464 12.2855 8.99824 12.5342 8.94921 12.814C8.89861 13.092 8.87442 13.3045 8.87442 13.4469C8.87442 13.8066 8.9546 14.0521 9.11537 14.1827C9.27731 14.3132 9.55624 14.3781 9.95243 14.3781C10.1384 14.3781 10.3501 14.3451 10.5852 14.2803C10.8197 14.2155 10.9907 14.1586 11.0968 14.1088ZM11.2908 2.73768C11.2908 3.20958 11.113 3.6126 10.7556 3.94399C10.3991 4.27655 9.96952 4.44297 9.46699 4.44297C8.96289 4.44297 8.53226 4.27655 8.17169 3.94399C7.81179 3.61247 7.63144 3.20958 7.63144 2.73768C7.63144 2.2667 7.81179 1.86302 8.17169 1.52743C8.5316 1.19237 8.96302 1.0249 9.46699 1.0249C9.96939 1.0249 10.3991 1.19276 10.7556 1.52743C11.1133 1.86302 11.2908 2.26683 11.2908 2.73768Z"
                        fill="#B9B9B9"
                      />
                    </svg>
                  </div>
                </template>
                <div class="tooltip-content">
                  <span
                    >Z-index defines the area polygons order on the map. +1
                    means that the area polygon is higher on the map</span
                  >
                </div>
                <div class="tooltip-arrow"></div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="form-row">
        <v-col cols="6">
          <div class="text-input-label">Default VAT</div>
          <v-text-field
            solo
            flat
            dense
            background-color="#F6F6F6"
            v-model="formData.vat_percentage"
            class="form-font pkmn-input error-border"
            type="number"
            min="0"
            suffix="%"
            :rules="[rules.onlyPositive]"
            :hint="vatHint"
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="text-input-label">Currency</div>
          <v-select
            solo
            class="pkmn-input "
            flat
            background-color="#F6F6F6"
            v-model="formData.currency_id"
            :items="currencyTypes"
            item-value="id"
            item-text="name"
            :rules="[rules.required]"
            dense
          >
            <template v-slot:append>
              <img :src="dropdownNarrow" />
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Merchant kick back in</div>
          <v-text-field
            v-model="formData.kick_back_fee_percentage"
            class="form-font pkmn-input error-border"
            background-color="#F6F6F6"
            type="number"
            min="0"
            dense
            solo
            flat
            :rules="[rules.onlyPositive]"
          >
            <template v-slot:append>
              <div class="append-icon">%</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Merchant kick back in</div>
          <v-text-field
            v-model="formData.kick_back_fee_fixed"
            class="form-font pkmn-input error-border"
            background-color="#F6F6F6"
            type="number"
            min="0"
            dense
            solo
            flat
            :rules="[rules.onlyPositive]"
          >
            <template v-slot:append>
              <div class="append-icon">{{ currencyIcon }}</div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="height: 72px">
        <v-col cols="6">
          <div class="form-label">Parkman commission in</div>
          <v-text-field
            v-model="formData.commission_percentage"
            class="form-font pkmn-input error-border"
            background-color="#F6F6F6"
            type="number"
            min="0"
            dense
            solo
            flat
            :rules="[rules.onlyPositive]"
          >
            <template v-slot:append>
              <div class="append-icon">%</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <div class="form-label">Parkman commission in</div>
          <v-text-field
            v-model="formData.commission_fixed"
            class="form-font pkmn-input error-border"
            background-color="#F6F6F6"
            type="number"
            min="0"
            solo
            flat
            dense
            :rules="[rules.onlyPositive]"
          >
            <template v-slot:append>
              <div class="append-icon">{{ currencyIcon }}</div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import dropdownNarrow from '@/assets/dropdown_arrow.svg'
import StatusBox from '@/components/Common/StatusBox.vue'
import rules from '@/helpers/validation'
import { CurrencyTypesEnum } from '@/enums'

export default {
  props: {
    location: {
      type: Object,
      default: null
    },
    merchant: {
      type: Object,
      default: null
    }
  },
  components: {
    StatusBox
  },
  data() {
    return {
      rules,
      dropdownNarrow,
      currencies: ['USD', 'EUR', 'GBP', 'DKK'],
      currencyTypes: CurrencyTypesEnum,
      formData: {
        z_index: 0,
        currency_id: null,
        vat_percentage: null,
        kick_back_fee_fixed: null,
        kick_back_fee_percentage: null,
        commission_fixed: null,
        commission_percentage: null
      },
      formDataCopy: ''
    }
  },
  methods: {
    rulesValidation() {
      const validated = this.$refs.settingsForm.validate()
      this.$emit('validated', validated)
      return validated
    },
    updateFormDataFromLocation(location) {
      Object.assign(this.formData, {
        z_index: location.z_index.toString(),
        currency_id: location.currency_id,
        vat_percentage: location.vat_percentage === null || location.vat_percentage === "" ? null : location.vat_percentage.toString(),
        kick_back_fee_fixed: location.kick_back_fee_fixed,
        kick_back_fee_percentage: location.kick_back_fee_percentage,
        commission_fixed: location.commission_fixed,
        commission_percentage: location.commission_percentage
      })
      this.formDataCopy = JSON.stringify(this.formData)
    }
  },
  computed: {
    vatHint() {
      return this.formData.vat_percentage === '' || this.formData.vat_percentage === null
        ? 'Country VAT by default'
        : ''
    },
    currencyIcon() {
      if (this.formData.currency_id) {
        return this.currencyTypes[this.formData.currency_id - 1].symbol
      }
      return null
    },
    isReviewing() {
      if (this.location) {
        return this.location.status_name === 'pending'
      } else {
        return false
      }
    },
    isWaitingForDeletion() {
      if (this.location) {
        return this.location.status_name === 'waiting_for_deletion'
      } else {
        return false
      }
    }
  },
  mounted() {
    if (this.location) {
      this.updateFormDataFromLocation(this.location)
    }

    if (this.merchant) {
      this.formData.currency_id = this.merchant.currency_id
      this.formData.vat_percentage = this.merchant.vat_percentage
      this.formData.kick_back_fee_fixed = this.merchant.kick_back_fee_fixed
      this.formData.kick_back_fee_percentage =
        this.merchant.kick_back_fee_percentage
      this.formData.commission_fixed = this.merchant.commission_fixed
      this.formData.commission_percentage = this.merchant.commission_percentage
    }
    this.$nextTick(() => {
      this.rulesValidation()
    })
  },
  watch: {
    formData: {
      handler(newValue) {
        this.rulesValidation()
        if (this.location) {
          const changed = JSON.stringify(this.formData) !== this.formDataCopy
          this.$emit('changed', changed)
        }
        this.$emit('formData', newValue)
      },
      deep: true
    },
    location(newValue) {
      if (newValue) {
        this.updateFormDataFromLocation(newValue)
      }
    },
    merchant(newValue) {
      if (newValue) {
        this.formData.currency_id = newValue.currency_id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
.advanced-settings-form {
  width: 339px;

  .v-select .v-select__selections {
    height: 48px;
    // padding: 0 12px;
  }
  .v-menu__content {
    .v-picker__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 339px) {
    width: 100%;
  }
}
.form-font .v-input__slot {
  --padding: 10px;
  background: #eaeaea !important;
}
.submit-button {
  position: absolute;
  bottom: 24px;
  width: 100%;
  max-width: 339px;
}
.append-icon {
  font-size: 16px !important;
  color: rgba(31, 31, 31, 0.5);
}

.tooltip-content {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  width: 168px;
  background-color: white;
  color: black;
  padding: 8px 16px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.add-user-table {
  padding-top: 32px !important;
}

.form-row {
  min-height: 60px;
  max-height: 70px;
}
</style>
