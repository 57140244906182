<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog">
      <h1 class="form-dialog__title">
        {{ $t('delete_location_title') }}
      </h1>
      <p class="form-dialog__subtitle" v-if="auth.isMerchantAdmin()">
        {{ $t('title.action_admin_needs') }}
      </p>
      <v-table class="table">
        <tr @click="deleteLocation" class="blackTr bottom-border">
          <td class="bottom-border">{{ $t('delete_location_button') }}</td>
          <td class="arrow--has-bottom-border">
            <img src="@/assets/arrows.svg" />
          </td>
        </tr>
        <tr @click="disableProduct" class="blackTr">
          <td>{{ $t('deactive_products_button') }}</td>
          <td class="arrow"><img src="@/assets/arrows.svg" /></td>
        </tr>
        <tr class="backTr">
          <td colspan="2">
            <v-row class="form-dialog__button-wrap">
              <v-col cols="12">
                <v-btn
                  class="pkmn-button"
                  outlined
                  color="primary"
                  @click="close"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </td>
        </tr>
      </v-table>
    </v-card>
  </v-dialog>
</template>

<script>
// import api from '@/helpers/api'
import * as config from '@/config/config'
import merchantHelpers from '@/helpers/merchant'
import auth from '@/auth'

export default {
  name: 'FormDeleteLocation',
  props: {
    data: Object
  },
  data() {
    return {
      auth: auth,
      alwaysOpen: true,
      errorMessage: null
    }
  },
  computed: {
    locationName() {
      return this.$store.getters.currentLocation.name
    },
    isMobile() {
      return window.innerWidth <= config.SMALL_SCREEN_BREAKPOINT
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async deleteLocation() {
      await this.$store.dispatch('setShowOverlay', true)
      if (auth.isParkmanAdmin()) {
        await merchantHelpers.deleteLocation(
          this.$route.params.locationId,
          'delete'
        )
      } else {
        await merchantHelpers.requestDeleteLocation(
          this.$route.params.locationId,
          'delete'
        )
      }
      this.$emit('locationDeleted')
      await this.$store.dispatch('setShowOverlay', false)
    },
    async disableProduct() {
      await this.$store.dispatch('setShowOverlay', true)
      if (auth.isParkmanAdmin()) {
        await merchantHelpers.deleteLocation(
          this.$route.params.locationId,
          'deactivate_products'
        )
      } else {
        await merchantHelpers.requestDeleteLocation(
          this.$route.params.locationId,
          'deactivate_products'
        )
      }
      this.$emit('productsDeactived')
      await this.$store.dispatch('setShowOverlay', false)
    }
  },
  mounted() {
    // if user press Escape, close the dialog and clear all data
    // ONLY works if a component is NOT attacted to the view as a default (use v-if to decide whether a component is attacted or not)
    // Otherwise, all 'close' listener of components existing in a view will be notified
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.title-size {
  width: 302px !important;
}
.blackTr {
  color: var(--web-text-main, #333);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  height: 56px;
  vertical-align: middle;
  cursor: pointer;
}
.bottom-border {
  width: 426px;
  border-bottom: 1px solid #e5e5e5;
}

.arrow {
  width: 24px;
  align-content: right;
  text-align: right;

  &--has-bottom-border {
    border-bottom: 1px solid #e5e5e5;
  }
}
</style>
